<template>
    <div>
        <el-dialog :title="title" :visible.sync="open" width="800px" @close="commitOnClose">
            <el-form ref="saveOrEditForm" :model="saveData" label-width="80px" style="width:600px">
                <el-form-item label="型号" prop="hardwareNo">
                    <template v-if="isAdd">
                        <el-input v-model="saveData.hardwareNo"
                              placeholder="请输入型号"/>
                    </template>
                    <template v-else>
                        {{saveData.hardwareNo}}
                    </template>

                </el-form-item>
                <el-form-item label="机具类型" prop="hardwareType">
                <el-select v-model="saveData.hardwareType" placeholder="请选择机具型号" style="width: 240px">
                    <el-option label="电签" value="1"/>
                    <el-option label="传统" value="2"/>
                    <el-option label="二维码" value="3"/>
                </el-select>
            </el-form-item>
                <el-form-item label="名称" prop="hardwareName">
                    <el-input v-model="saveData.hardwareName"
                              placeholder="请输入硬件名称"/>
                </el-form-item>
				<el-form-item label="上游通道" prop="channelNo">
					<el-select v-model="saveData.channelNo" placeholder="上游通道" style="width: 240px">
						<el-option v-for="item in acqChannelList" :label="item.name" :value="item.channelNo" :key="item.channelNo"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="厂商" prop="companyType">
                    <el-input v-model="saveData.companyType"
                              placeholder="请输入厂商"/>
                </el-form-item>
                <el-form-item label="通信模块" prop="flowCardType">
                    <el-select v-model="saveData.flowCardType" placeholder="请选择通信模块" style="width: 240px">
						<el-option label="2G" value="2G"></el-option>
						<el-option label="4G" value="4G"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="采购价格" prop="purchasePrice">
                    <el-input v-model="saveData.purchasePrice"
                              placeholder="请输入采购价格"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">提交</el-button>
                <el-button type="primary" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {TerminalApi} from '@/api';
    export default {
        name: "CustomForm",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            isAdd: {
                type: Boolean,
                default: true,
            },
            selectRow: {
                type: Object,
                default: function () {
                    return {}
                },
            },
            onFresh: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                title: '',
                saveData: {},
                open: false,
				acqChannelList: []
            }
        },
        watch: {
            onFresh: function (value) {
                this.open = value;
                if (value) {
                    this.resetData();
                }
            }
        },
        async mounted() {
			const result = await TerminalApi.acqChannel.listAllChannel();
			this.acqChannelList = result && result.data || [];
        },
        methods: {
            async resetData() {
                if (this.isAdd){
                    this.title = "添加硬件";
                    this.saveData = {};
                }else {
                    this.saveData = this.selectRow;
                    this.isAdd = false;
                    this.title = "修改硬件";
                }
            },
            async submitForm() {
                let result = (this.isAdd) ?
                        (await TerminalApi.hardwareInfo.save(this.saveData)) :
                        (await TerminalApi.hardwareInfo.editHardware(this.saveData));
                if (result.success) {
                    this.Message.success(this.isAdd ? '添加成功' : '修改成功');
                    this.commitOnClose();
                } else{
                    this.Message.error('修改失败，请稍后再试')
                }
            },
            cancel() {
                this.saveData = {};
                this.commitOnClose();
            },
            commitOnClose() {
                this.$emit('on-close');
            }
        }
    };
</script>

<style scoped>

</style>
